<template>
  <div>
    <v-row class="d-flex" align="start" justify="space-between" fluid>
      <v-col cols="5">
        <v-row
          align="center"
          v-for="utm in filterData.utm_filters"
          :key="utm.text"
          justify="space-between"
        >
          <v-col cols="4">
            <span class="text-subtitle-2">{{ utm.text }}: </span>
          </v-col>
          <v-col cols="7" class="d-flex align-center">
            <v-switch
              inset
              v-model="utm.show"
              hide-details="auto"
              @change="getFiltereUTM(utm.text)"
              :disabled="utm.loading"
              color="success"
            ></v-switch>
            <v-autocomplete
              v-model="utm.value"
              :items="utm.values"
              outlined
              hide-details
              dense
              :disabled="!utm.show"
              :loading="utm.loading"
              color="success"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="7">
        <v-simple-table class="my-table supply" dense>
          <thead>
          <tr>
            <th class="text-right"></th>
            <th class="text-right">{{ $t('table.created') }}</th>
            <th class="text-right">{{ $t('table.pending') }}</th>
            <th class="text-right">{{ $t('table.sent') }}</th>
            <th class="text-right">{{ $t('table.returned') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(data, index) in chartData" :key="index">
            <td class="text-right">{{ data.date }}</td>
            <td class="text-right">{{ data.amount }}</td>
            <td class="text-right">{{ getPendingBasic(data) }}</td>
            <td class="text-right">{{ data.sent_amount }}</td>
            <td class="text-right">{{ data.return_amount }}</td>
          </tr>
          <tr style="border-top:2px solid;">

            <th class="text-right">{{ $t('cabinet.total') }}</th>
            <th class="text-right">
              {{ basic.amount }}
            </th>
            <th class="text-right">
              {{ basic.pending }}
            </th>
            <th class="text-right">
              {{ basic.sent }}
            </th>
            <th class="text-right">
              {{ basic.returned }}
            </th>
          </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {validationMixin} from "vuelidate";
import notifications from "@/mixins/notifications";


export default {
  mixins: [notifications, validationMixin],
  computed: {},
  props: {
    filterData: {
      require: true,
    },
    chartData: {
      require: true,
    },
    basic: {
      require: true,
    },
  },
  methods: {
    getFiltereUTM(utmValue) {
      this.$emit("getFiltereUTM", utmValue)
    },
    getPendingBasic(data) {
      return parseFloat(data.amount) - parseFloat(data.sent_amount);
    },
  },
  data: () => ({}),
};
</script>

<style></style>

<script setup>
</script>
