<template>
  <v-dialog
    v-model="visibility"
    max-width="1200"
    content-class="cross__dialog"
    :transition="$dialogTransition"
  >
    <v-card>
      <v-fade-transition>
        <v-progress-linear
          v-if="loading"
          absolute
          top
          indeterminate
          color="success"
        ></v-progress-linear>
      </v-fade-transition>
      <v-slide-x-transition>
        <div class="cross__inner" v-if="!loading">
          <v-btn fab small elevation="0" @click="onCancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-slide-x-transition>
      <v-card-title>
        {{ $t("crm.gallery_title") }}
        <div>
          <label for="product_photo_add_mobile" class="text-black">
            <div class="gallery-mobile-plus">
              <v-btn icon style="pointer-events: none;"
                ><v-icon color="success">mdi-plus-circle</v-icon></v-btn
              >
            </div>
          </label>
          <VFileInput
            class="d-none"
            id="product_photo_add_mobile"
            accept="image/png, image/jpeg, image/jpg"
            v-model="image"
            @change="makePreview"
          />
        </div>
      </v-card-title>
      <v-card-text>
        <v-row style="max-height: 80vh; overflow-y: auto;" class="gallery-row">
          <div
            v-for="image in images"
            :key="image.uuid"
            class="d-flex align-center justify-center pa-3"
          >
            <v-card class="gallery-card" elevation="0">
              <v-slide-x-transition>
                <v-btn
                  absolute
                  style="right: 8px; top: 8px; z-index: 105"
                  class="btn-edit"
                  color="error"
                  @click="onDelete(image)"
                  ><v-icon class="text-white">mdi-delete-outline</v-icon></v-btn
                ></v-slide-x-transition
              >
              <v-img
                @click="showImgDialog(image)"
                :src="image.path_img_thumb || require('@/assets/images/product-desc.png')"
                class="gallery-card-img mb-0 pb-0 br-10 ma-auto h-100"
                cover
                style="width: 250px; height: 250px; object-fit: contain;"
              />
            </v-card>
          </div>
          <div class="gallery-add d-flex align-center justify-center pa-3">
            <v-card class="d-flex align-center justify-center gallery-card" elevation="0">
              <label for="product_photo_add" class="text-black">
                <div class="text-center d-flex justify-center">
                  <div
                    style="width: 250px; height: 250px; "
                    class="d-flex br-10 justify-center align-center photo-area"
                    v-if="!photoPreview"
                  >
                    <div :style="$vuetify.theme.dark ? 'color: #fff' : 'color :#000'">
                      <v-icon>mdi-plus</v-icon><br />
                      {{ $t("catalog.add_photo") }}
                    </div>
                  </div>
                  <img
                    v-else
                    :src="photoPreview"
                    alt=""
                    style="width: 250px; height: 250px;"
                    class="br-10"
                  />
                </div>
              </label>
              <VFileInput
                class="d-none"
                id="product_photo_add"
                accept="image/png, image/jpeg, image/jpg"
                v-model="image"
                @change="makePreview"
              />
            </v-card>
          </div>
        </v-row>
      </v-card-text>
    </v-card>
    <imgDialog :visible="imgDialog" @close="imgDialog = false" :imgpath="imgpath" />
    <confirm-dialog
      :visible="deleteConfirm"
      @close="deleteConfirm = false"
      @onConfirm="deleteImage(chosenDeleteItem)"
      @onCancel="deleteConfirm = false"
      :typeAlert="'error'"
    >
      <template v-slot:header>{{ $t("crm.gallery_delete_confirm_title") }}</template>
      <template v-slot:description>{{
        $t("crm.gallery_delete_confirm_desc")
      }}</template></confirm-dialog
    >
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import fileSizeValidation from "@/validators/fileSizeProduct";
import galleryService from "@/services/request/gallery/galleryService";
import notifications from "@/mixins/notifications";
import imgDialog from "@/components/products/imgDialog.vue";
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue";

export default {
  data: () => ({
    loading: false,
    image: null,
    photoPreview: null,
    images: [],
    imgDialog: false,
    imgpath: "",
    deleteConfirm: false,
    chosenDeleteItem: null
  }),
  mixins: [validationMixin, notifications],
  validations: {
    image: { fileSizeValidation }
  },
  props: {
    visible: {
      require: true
    },
    dataProduct: {
      required: true,
      type: Object,
      default: null
    }
  },
  components: {
    imgDialog,
    ConfirmDialog
  },
  async mounted() {
    await this.onMounted();
  },
  methods: {
    onCancel() {
      this.$emit("close");
    },
    onDelete(item) {
      this.chosenDeleteItem = item;
      this.deleteConfirm = true;
    },
    makePreview() {
      if (this.$v.image.fileSizeValidation) {
        let photoPreview = null;
        const reader = new FileReader();
        reader.readAsDataURL(this.image);
        reader.onloadend = ev => {
          photoPreview = ev.target.result;
          this.photoPreview = photoPreview;
        };
        this.addImage();
      } else {
        this.image = null;
        this.photoPreview = null;
        this.setErrorNotification(this.$t("form.errors.photoMaxSizeProduct"));
      }
    },
    async onMounted() {
      this.loading = true;
      this.images = await galleryService.getList({ uuid_object: this.dataProduct.uuid });
      this.loading = false;
    },
    async addImage() {
      this.loading = true;
      const formData = new FormData();
      formData.append("uuid_object", this.dataProduct.uuid);
      formData.append("image", this.image);
      this.image = null;
      await galleryService.addImage(formData);
      await this.onMounted();
      this.photoPreview = null;
      this.loading = false;
    },
    async deleteImage(image) {
      this.loading = true;
      await galleryService.deleteImage(image.uuid);
      await this.onMounted();
      this.deleteConfirm = false;
      this.loading = false;
    },
    showImgDialog(item) {
      this.imgpath = item.path_img;
      this.imgDialog = true;
    }
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        return this.$emit("close");
      }
    }
  }
};
</script>

<style scoped lang="scss">
.gallery-card {
  width: 250px;
  height: 250px;
  border: thin solid #e3e3e3;
  &-img:hover {
    opacity: 0.9;
    cursor: pointer;
  }
}
.gallery-mobile-plus {
  display: none;
}
.photo-area:hover {
  cursor: pointer;
  opacity: 0.7;
}
@media (max-width: 625px) {
  .gallery-row {
    justify-content: center;
  }
  .gallery-add {
    display: none !important;
  }
  .gallery-mobile-plus {
    display: block;
  }
}
</style>
