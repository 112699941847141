import { render, staticRenderFns } from "./boardCashiersList.vue?vue&type=template&id=2c7c5fa9&"
import script from "./boardCashiersList.vue?vue&type=script&lang=js&"
export * from "./boardCashiersList.vue?vue&type=script&lang=js&"
import style0 from "./boardCashiersList.vue?vue&type=style&index=0&id=2c7c5fa9&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
installComponents(component, {VBtn,VCardSubtitle,VCol,VIcon,VRadio,VRadioGroup})
