<template>
  <div>
    <v-simple-table class="my-table supply" dense>
      <thead>
      <tr>
        <th class="text-right">{{$t('table.date')}} </th>
        <th class="text-right">{{$t('table.total_price_init')}}  ({{$t('paymentPlan.uah')}})</th>
        <th class="text-right">{{$t('table.total_price')}} ({{$t('paymentPlan.uah')}})</th>
        <th class="text-right">{{$t('table.total_price_profit')}}  ({{$t('paymentPlan.uah')}})</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(data, index) in chartData" :key="index">
        <td class="text-right">{{ data.date }} </td>
        <td class="text-right">{{ data.total_price_init }}</td>
        <td class="text-right">{{ data.total_price }}</td>
        <td class="text-right">{{ data.total_price_profit }}</td>
      </tr>
      <tr style="border-top:2px solid;">

        <th class="text-right">{{$t('cabinet.total')}}</th>
        <th class="text-right"  v-for="index in 3" :key="index">
          {{ getTotalProfit(index - 1)}}
        </th>
      </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  props: {
    chartData: {
      require: true,
    },
  },
  methods: {
    getTotalProfit(index) {
      if (!this.chartData.length) return "--";
      const keys = ["total_price_init", "total_price","total_price_profit"]
      const str = this.chartData.map((e) => e[keys[index]] || 0).join("+");
      if (!str) return;
      // eslint-disable-next-line
      return eval(str).toFixed(2);
    },
  }
};
</script>

<style lang="scss" scoped></style>
