<template>
  <div>
    <v-simple-table class="my-table supply" dense>
      <thead>
      <tr>
        <th class="text-right">{{$t('form.title')}} </th>
        <th class="text-right">{{$t('table.amount')}} Замовлень</th>
        <th class="text-right">{{$t('table.TotalPrice')}} ({{$t('paymentPlan.uah')}})</th>
        <th class="text-right">{{$t('table.price_additional_sell_total')}}  ({{$t('paymentPlan.uah')}})</th>
        <th class="text-right">{{$t('table.total_sell_items_amount')}} </th>
        <th class="text-right">{{$t('table.total_additional_sell_amount')}} </th>
        <th class="text-right">{{$t('table.item_price_extra')}}  ({{$t('paymentPlan.uah')}})</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(data, index) in filteredAssigned" :key="index">
        <td class="text-right">
          {{ (data.name && data.surname)  ? `${data.name} ${data.surname}`: "Не визначено" }}
        </td>
        <td class="text-right">{{ data.amount || 0.00}}</td>
        <td class="text-right">{{ data.price_total || 0.00}} </td>
        <td class="text-right">{{ data.price_additional_sell_total || 0.00}}</td>
        <td class="text-right">{{ data.total_sell_items_amount || 0}}</td>
        <td class="text-right">{{ data.total_additional_sell_amount || 0}}</td>
        <td class="text-right">{{ data.price_extra_total || 0.00}}</td>
      </tr>
      <tr style="border-top:2px solid;">
        <th class="text-right">{{$t('cabinet.total')}}</th>
        <th class="text-right"  v-for="index in 6" :key="index">
          {{ getTotal(index - 1)}}
        </th>
      </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>


export default {
  props: {
    filteredAssigned: {
      require: true,
    },
    chartData: {
      require: true,
    },
  },
  methods: {
    getTotal(index) {
      if (!this.chartData.length) return "--";
      const keys = ["amount", "price_total", "price_additional_sell_total", "total_sell_items_amount", "total_additional_sell_amount", "price_extra_total"];
      const str = this.filteredAssigned.map((e) => e[keys[index]] || 0).join("+");
      if (!str) return;

      const noToFixedKeys = ["amount", "total_sell_items_amount", "total_additional_sell_amount"];
      // eslint-disable-next-line no-eval
      const result = eval(str);

      if (noToFixedKeys.includes(keys[index])) {
        return result.toString();
      } else {
        return result.toFixed(2);
      }
    },
  }
};
</script>

