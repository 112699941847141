<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <VBtn
          class="text-white success-bg btn-settingBoard  text-transform-none br-10"
          @click="isShareUserVisible()"
          v-bind="attrs"
          v-on="on"
        >
          <VIcon >mdi-share-variant</VIcon>
        </VBtn>
        </template>
        <span>{{$t('crm.accesTitle')}}</span>
    </v-tooltip>
    <VDialog
      v-model="shareUserVisible"
      max-width="700px"
      content-class="cross__dialog dialog-order"
      scrollable
      :transition="$dialogTransition"
    >
      <VCard class="boardSettingDialog" :height="$vuetify.breakpoint.smAndDown ? '500' : ''">
        <div class="cross__inner">
          <VBtn fab small elevation="0" @click="closeDialog">
            <VIcon>mdi-close</VIcon>
          </VBtn>
        </div>
        <VCardTitle>
          <VRow class="ma-0 align-center justify-space-between boardSettingDialog__header">
            <VCol cols="12" md="6" class="boardSettingDialog__header-title pb-0 pl-0 pt-3">
              {{ $t("crm.accesTitle") }}
              «<b>{{ editBoard.name }}</b
              >»
            </VCol>
          </VRow>
        </VCardTitle>
        <VDivider class="mx-6 pb-1"></VDivider>
        <VCardText>
          <VRow class="my-0 py-0">
            <VCol cols="12" class="d-flex">
              <VTextField
                v-model="sharedUser.email"
                outlined
                dense
                color="green"
                class="br-10"
                :label="$t('form.email')"
                :error-messages="emailSharedErrors"
                :disabled="loadingAddUser || loadingAdd"
                hide-details="auto"
                @keypress="validationForEmail($event)"
                @paste="validationForEmail($event)"
              />
              <VBtn
                class="ml-2 btn-settingBoard success-bg text-white text-transform-none br-10"
                :disabled="loadingAddUser || loadingAdd"
                :loading="loadingAddUser || loadingAdd"
                @click="onShowCondirmAdd"
              >
                <VIcon>mdi-plus</VIcon>
              </VBtn>
            </VCol>
            <VCol cols="12">
              <div class="pt-0">{{ $t("crm.userList") }}:</div>
              <VDivider />
              <v-data-table
                :headers="sharedUsersHeader"
                :items="sharedUsers"
                item-key="email"
                hide-default-footer
                class="my-table supply"
                locale="uk"
                dense
                :loading="loadingListUser || loadingAddUser"
                loader-height="1"
                :items-per-page="sharedUsers.length"
                :no-data-text="$t('table.noData')"
              >
                <!-- eslint-disable-next-line -->
                <template v-slot:item.edit-btn="{ item }">
                  <VBtn
                    class="btn-edit sm"
                    color="primary"
                    depressed
                    dense
                    small
                    @click="onEditUser(item)"
                  >
                    <VIcon small>
                      mdi-file-document-edit-outline
                    </VIcon>
                  </VBtn>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.number="{ index }">
                  {{ ++index }}
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.email="{ item }">
                  <div class="copyTxt" @click="CopyToClipboard(item.user_details.email)">
                    {{ item.user_details.email }}
                  </div>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.btn="{ item }">
                  <VBtn
                    icon
                    class="sm"
                    color="error"
                    depressed
                    dense
                    small
                    :disabled="loadingDelete"
                    :loading="loadingDelete"
                    plain
                    @click="onShowConfirmDelete(item.uuid)"
                  >
                    <VIcon small>
                      mdi-close
                    </VIcon>
                  </VBtn>
                </template>
              </v-data-table>
            </VCol>
          </VRow>
        </VCardText>
      </VCard>
    </VDialog>
    <ConfirmDialog
      :visible="visibleConfirmAddUser"
      @close="visibleConfirmAddUser = false"
      @onConfirm="addUserOnBoard"
      @onCancel="visibleConfirmAddUser = false"
      :typeAlert="'success'"
    >
      <template v-slot:header>
        {{ $t('confirmDialog.addAccesTitle') }}
      </template>
      <template v-slot:description>
        {{ $t('confirmDialog.confirmAddUser') }} <b>{{ editBoard.name }}</b
        >?
      </template>
    </ConfirmDialog>
    <ConfirmDialog
      :visible="confirmDelete.visible"
      :data="confirmDelete.data"
      @close="confirmDelete.visible = false"
      @onConfirm="onDeleteSharedUser"
      @onCancel="confirmDelete.visible = false"
      :typeAlert="'error'"
    >
      <template v-slot:header>
        {{ $t('confirmDialog.delete_user_title') }}
      </template>
      <template v-slot:description>
        {{ $t('confirmDialog.delete_user') }} <b>{{ editBoard.name }}</b
      >?
      </template>
    </ConfirmDialog>
    <EditUserDialog
      v-if="editUserDialogVisibility"
      :visible="editUserDialogVisibility"
      :item="editUserDialogItem"
      @close="editUserDialogVisibility = false"
      @update_user="updateSharedUser"
      :loadingSharedUpdate="loadingSharedUpdate"
    />
    <CreateNewUserSharedDialog
      v-if="createUserVisible"
      :visible="createUserVisible"
      :email="sharedUser.email"
      @onCancel="createUserVisible = false"
      @onConfirm="addUserOnBoard"
      @close="createUserVisible = false"
    />
  </div>
</template>

<script>
import user from "@/mixins/user";
import notifications from "@/mixins/notifications";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import EventBus from "@/events/EventBus";
import crmService from "@/services/request/crm/crmService";
import * as getterTypes from "@/store/modules/crm/types/getters";
import EditUserDialog from "./EditUserDialog.vue";
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue";
import CreateNewUserSharedDialog from './CreateNewUserSharedDialog.vue';
import {validationForEmail} from "@/mixins/helpers";

export default {
  name: "shareUserDialog",
  components: {
    ConfirmDialog,
    EditUserDialog,
    CreateNewUserSharedDialog,
  },
  data: () => ({
    boardItem: null,
    shareUserVisible: false,
    sharedUser: {},
    sharedUsers: [],
    sharedUsersHeader: [],
    confirmDelete: {
      visible: false,
      data: ""
    },
    editUserDialogVisibility: false,
    editUserDialogItem: {},
    loadingSharedUpdate: false,
    loadingDelete: false,
    loadingAddUser: false,
    loadingAdd: false,
    loadingListUser: false,
    visibleConfirmAddUser: false,
    createUserVisible: false,
    editBoard: {
      name: "",
      description: "",
      uuid: ""
    }
  }),
  mounted() {
    EventBus.$on("on-update-user", data => {
      this.updateSharedUser(data);
    });
  },
  methods: {
    validationForEmail,
    isShareUserVisible() {
      this.renderTable();
      this.shareUserVisible = !this.shareUserVisible
    },
    onEditUser(item) {
      const access = {
        accessDeport: item.is_access_deport_info === "1",
        changeStatus: item.is_allow_state_edit === "1",
        allowSendOrder: item.is_allow_send_order === "1",
        allowAssignedUserChange: item.is_allow_assigned_user_change  === "1",
        allowStatisticReview: item.is_allow_statistic_review  === "1",
        allowSeeAllDeliveries: item.is_allow_all_orders_review  === "1",
        allowSeeDeliveriesUtmMarks: item.is_allow_utm_match_orders_review  === "1",

      };
      this.editUserDialogItem = { ...access, ...item };
      this.editUserDialogVisibility = true;
    },
    closeDialog() {
      this.shareUserVisible = false;
      this.sharedUser = {};
      this.$v.$reset();
    },
    fetchData() {

      const boardItem = this.boardList.filter(item => item.uuid === this.uuid_board);
      this.boardItem = boardItem.length ? boardItem[0] : null;
      this.fetchSharedUsers();
      this.getDataItemBoard();
    },
    async getDataItemBoard() {
      try {
        if (this.boardItem) {
          await crmService.getCrmBoardsItemInfo(this.boardItem.uuid).then(request => {
            // // orderDialog('request', request);
            this.editBoard = {
              ...this.editBoard,
              ...request
            };
          });
        }
      } catch (e) {
        console.log(e)
      }
    },
    async onDeleteSharedUser(uuidUser) {
      try {
        this.loadingDelete = true;
        await crmService.shareCrmBordDeleteUser(uuidUser);
        this.setSuccessNotification("Користувача успішно видалено!");
        this.fetchSharedUsers();
      } catch (e) {
        console.log(e)
      } finally {
        this.confirmDelete = {
          visible: false,
          data: ""
        };
        this.loadingDelete = false;
      }
    },
    async fetchSharedUsers() {
      try {
        const params = {
          uuid_board: this.boardItem.uuid
        };
        this.loadingListUser = true;
        const res = await crmService.shareCrmBordUserList(params);
        this.renderTable();
        this.sharedUsers = res;
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingListUser = false;
      }
    },
    onShowConfirmDelete(uuidUser) {
      this.confirmDelete.visible = true;
      this.confirmDelete.data = uuidUser;
    },
    async updateSharedUser(data) {
      try {
        this.loadingSharedUpdate = true;
        const formData = new FormData();
        formData.append("uuid", data.uuid);

        data.is_allow_state_edit = data.accessParams.is_allow_state_edit ? "1" : "0"
        data.is_access_deport_info = data.accessParams.is_access_deport_info ? "1" : "0"
        data.is_allow_send_order = data.accessParams.is_allow_send_order ? "1" : "0"
        data.is_allow_assigned_user_change = data.accessParams.is_allow_assigned_user_change ? "1" : "0"
        data.is_allow_statistic_review = data.accessParams.is_allow_statistic_review ? "1" : "0"
        data.is_allow_all_orders_review = data.accessParams.is_allow_all_orders_review ? "1" : "0"
        data.is_allow_utm_match_orders_review = data.accessParams.is_allow_utm_match_orders_review ? "1" : "0"
        data.changeStatus = this.editUserDialogItem.changeStatus
        data.allowSendOrder = this.editUserDialogItem.allowSendOrder
        data.accessDeport = this.editUserDialogItem.accessDeport
        data.allowAssignedUserChange = this.editUserDialogItem.allowAssignedUserChange
        data.allowStatisticReview = this.editUserDialogItem.allowStatisticReview
        data.allowSeeAllDeliveries = this.editUserDialogItem.allowSeeAllDeliveries
        data.allowSeeDeliveriesUtmMarks = this.editUserDialogItem.allowSeeDeliveriesUtmMarks

        const changedData = this.$getChangedData(data, this.editUserDialogItem);
        changedData.forEach(data => {
          formData.append(data.field, data.value);
        });

        this.loadingListUser = true;
        await crmService.shareCrmBordUserUpdate(formData);
        this.editUserDialogVisibility = false;

        this.setSuccessNotification(this.$t("users.user_update_success"));
        this.fetchSharedUsers();
        this.renderTable();
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingListUser = false;
        this.loadingSharedUpdate = false;
      }
    },
    async addUserOnBoard() {
      try {
        this.loadingAddUser = true;
        const formData = new FormData();
        formData.append("uuid_board", this.uuid_board);
        formData.append("email", this.sharedUser.email);
        const res = await crmService.shareCrmBordByEmail(formData);
        if (res) {
          this.fetchSharedUsers();
          this.$v.sharedUser.$reset();
          this.sharedUser = {};
          this.setSuccessNotification("Користувача успішно додано!");
        }
      } catch (e) {
        this.setErrorNotification(e.error.description.slice(0, 24));
      } finally {
        this.visibleConfirmAddUser = false;
        this.createUserVisible = false
        this.loadingAddUser = false;
      }
    },
    CopyToClipboard(data) {
      navigator.clipboard.writeText(data);
      this.setSuccessNotification(this.$t('profile.copied_to_clipboard'));
    },
    async onShowCondirmAdd() {
      this.$v.sharedUser.$touch();
      if (this.$v.sharedUser.$invalid) return;

      const data = {
        email: this.sharedUser.email
      }
      this.loadingAdd = true
      await crmService.checkSharedUserByEmail(data).then((res) => {

        this.loadingAdd = false
        if (res.is_share_allowed) {
          this.visibleConfirmAddUser = true;
        }
        else {
          this.createUserVisible = true;
        }
      })
      // const userExists = false
      // if (userExists) {
      //   this.visibleConfirmAddUser = true;
      // }
      // else {
      //   this.createUserVisible = true;
      // }
    },
    renderTable() {
      this.sharedUsersHeader = [
        {
          text: this.$t("table.action"),
          align: "center",
          sortable: false,
          value: "edit-btn"
        },
        {
          text: "#",
          align: "center",
          sortable: false,
          value: "number"
        },
        {
          text: this.$t("form.name"),
          align: "center",
          sortable: false,
          value: "user_details.name"
        },
        {
          text: this.$t("form.surname"),
          align: "center",
          sortable: false,
          value: "user_details.surname"
        },
        {
          text: this.$t("form.email"),
          align: "center",
          sortable: false,
          value: "email"
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "btn"
        }
      ];
    }
  },
  computed: {
    ...mapGetters("crm", {
      uuid_board: getterTypes.GET_UUID_BOARD
    }),
    emailSharedErrors() {
      const errors = [];
      if (!this.$v.sharedUser.email.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.sharedUser.email.required && errors.push(this.$t("form.errors.EmailRequired"));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.sharedUser.email.email && errors.push(this.$t("form.errors.EmailMustBeValid"));
      return errors;
    }
  },
  validations: {
    sharedUser: {
      email: { required, email }
    }
  },
  props: {
    boardList: {
      required: true
    },
    boardUuid: {
      required: true
    },
    stateUuid: {
      required: true
    }
  },
  watch: {
    shareUserVisible:{
      deep:true,
      handler(e){
        if(this.shareUserVisible == true) {
          this.fetchData();
        }
      }
    },
  },
  mixins: [validationMixin, notifications, user]
};
</script>

<style></style>
