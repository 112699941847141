import { render, staticRenderFns } from "./StatsFilterProfit.vue?vue&type=template&id=16638164&scoped=true&"
import script from "./StatsFilterProfit.vue?vue&type=script&lang=js&"
export * from "./StatsFilterProfit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16638164",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VSimpleTable})
