<template>
  <v-dialog max-width="500px" v-model="visibility" content-class="cross__dialog" :persistent="isChanged" @click:outside="onCancelHandler()" scrollable>
    <v-card :loading="loading" max-height="300px">
      <div class="cross__inner">
        <v-btn
          fab
          small
          elevation="0"
          @click="onCancelHandler()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-title>
        <v-row no-gutters class="w-100">
          <div v-if="typeDialog == 0">{{$t('crm.all_categories')}}</div>
          <div v-if="typeDialog == 1 && editedItem == editItem">{{$t('crm.create_category')}}</div>
          <div v-if="typeDialog == 1 && editedItem != editItem">{{$t('crm.update_category')}}</div>
          <v-spacer />
          <v-btn
            @click="changeType(1, editItem)"
            color="success"
            dark
            small
            :class="$vuetify.breakpoint.smAndDown ? 'mr-8 br-10' : 'mr-1 br-10'"
            v-if="typeDialog == 0"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-row>
        <v-row no-gutters>
          <v-btn
            @click="onBack()"
            color="primary"
            small
            plain
            class="br-10 pl-0"
            v-if="typeDialog != 0"
          >
            <v-icon small>mdi-arrow-left</v-icon>{{$t('btn.back')}}
          </v-btn>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-tabs-items v-model="typeDialog">
          <v-tab-item>
            <v-simple-table :dense="!isLarge" v-if="categories.length > 0" class="my-table">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">{{$t('form.title')}}</th>
                    <th class="text-center">
                      <v-icon dense>mdi-cogs</v-icon>
                    </th>
                  </tr>
                </thead>
                <tbody name="fade" is="transition-group">
                  <tr v-for="(category, index) in categories" :key="index">
                    <td class="text-left">{{ category.name || "--" }}</td>
                    <td class="text-center sm">
                      <v-btn
                        color="primary"
                        small
                        @click="changeType(1, category)"
                        class="btn-edit"
                        :class="{ sm: !isLarge }"
                      >
                        <v-icon dense>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn
                        color="error"
                        small
                        @click="deleteItem(category)"
                        class="btn-edit ml-2"
                        :class="{ sm: !isLarge }"
                      >
                        <v-icon dense>mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-row
              v-else
              align="center"
              justify="center"
              style="height: 50px"
              no-gutters
            >
              {{ $t("table.noData") }}
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <categories-edit
              v-if="typeDialog == 1"
              :editedItem="editedItem"
              :editItem="editItem"
              :formUuid="formUuid"
              @mutated="mutatedCategories"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
    <confirm-dialog
      :visible="confirmDelete"
      :data="deletedCategory"
      @close="confirmDelete = false"
      @onConfirm="onDelete"
      @onCancel="confirmDelete = false"
      :typeAlert="'error'"
    >
      <template v-slot:header>{{$t('crm.category_delete')}}</template>
      <template v-slot:description>
        {{$t('crm.category_delete_info')}}
      </template></confirm-dialog
    >
    <ConfirmLeftDialog
      :visible="visibleConfirm"
      @close="visibleConfirm = false"
      @delete="isClose ? $emit('close_modal') : typeDialog = 0; visibleConfirm = false; isChanged = false;"
    />
  </v-dialog>
</template>

<script>
import crmFormsService from "@/services/request/crm/crmForms";
import notifications from "@/mixins/notifications";
import SizeUi from "@/mixins/SizeUi";
import categoriesEdit from "./categoriesEdit.vue";
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue";
import EventBus from "@/events/EventBus";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";

export default {
  components: {
    ConfirmLeftDialog,
    categoriesEdit,
    ConfirmDialog,
  },
  props: {
    formUuid: {
      require: true,
    },
    visible: {
      require: true,
    },
  },
  data: () => ({
    isClose: false,
    visibleConfirm: false,
    isChanged: false,
    categories: [],
    typeDialog: 0,
    loading: false,
    editedItem: {
      name: ''
    },
    editItem: {
      name: ''
    },
    confirmDelete: false,
    deletedCategory: {},
  }),
  mixins: [notifications, SizeUi],
  mounted() {
    EventBus.$on("is-changed", (isChanged) => {
      this.isChanged = isChanged;
    });
    this.getCategories();
  },
  methods: {
    checkChanged(isChanged) {
      this.isChanged = isChanged
    },
    onCancelHandler() {
        if (this.isChanged) {
          this.visibleConfirm = true;
          this.isClose = true;
        }
        else {
          this.$emit('close_modal');
          this.isClose = false;
        }
    },
    onBack() {
        if (this.isChanged) {
          this.visibleConfirm = true;
          this.isClose = false;
        }
        else {
          this.typeDialog = 0
        }
    },
    deleteItem(category) {
      this.deletedCategory = category;
      this.confirmDelete = true;
    },
    async onDelete() {
      try {
        await crmFormsService
          .deleteCategory(this.deletedCategory.uuid)
          .then(() => {
            this.confirmDelete = false;
            this.getCategories();
          });
      } catch (e) {
        this.confirmDelete = false;
        console.log(e)
      }
    },
    changeType(type, edit) {
      this.typeDialog = type;
      this.editedItem = edit;
    },
    mutatedCategories() {
      this.getCategories();
      this.typeDialog = 0;
    },
    async getCategories() {
      try {
        this.loading = true;
        const data = {
          uuid_form: this.formUuid,
        };
        this.categories = await crmFormsService.getCategories(data);
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e)
      }
    },
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close_modal");
      },
    },
  },
};
</script>
