<template>
  <VCard
    :color="bgItem"
    class="my-5 mx-2 br-10 pb-3 px-2 pt-2"
    :ripple="{ class: 'blue--text ' }"
    :elevation="3"
    v-on:dblclick="onSupplyItemClick(supplyItem.uuid)"
  >
    <VRow no-gutters justify="end">
      <VBadge
        overlap
        dot
        color="warning"
        v-if="
          supplyItem.cell_details?.description !== '' && supplyItem.cell_details?.description !== ' '
        "
      >
        <VBtn
          x-small
          circle
          color="primary"
          :elevation="1"
          class="mt-1 mr-1 btn-edit sm circle"
          @click="onSupplyItemClick"
          ><VIcon x-small>mdi-file-document-edit-outline</VIcon></VBtn
        >
      </VBadge>
      <VBtn
        v-else
        x-small
        circle
        color="primary"
        :elevation="1"
        class="mt-1 mr-1 btn-edit sm circle"
        @click="onSupplyItemClick"
        ><VIcon x-small>mdi-file-document-edit-outline</VIcon></VBtn
      >
    </VRow>
    <VCardText>
      <VRow no-gutters justify="space-between" align-center>
        <VCol cols="12" sm="5">
          <VRow no-gutters justify="space-between">
            <VCol cols="5" class="font-weight-bold text-caption">{{ $t("table.number") }}</VCol>
            <VCol cols="1">–</VCol>
            <VCol
              cols="6"
              class="text-caption text-right elispsis copyTxt-mobile"
              @click="copyToClipboard(supplyItem.number)"
            >
              {{ supplyItem.number || "--" }}</VCol
            >
          </VRow>
          <VRow no-gutters>
            <VCol cols="5" class="font-weight-bold text-caption">ТТН</VCol>
            <VCol cols="1">–</VCol>
            <VCol
              cols="6"
              class="text-caption text-right "
              v-if="supplyItem.ttn == 0 || supplyItem.ttn == null"
            >
              <div class="new__wrapper sm">
                <span>{{ $t("table.new") }}</span>
              </div></VCol
            >
            <VCol
              cols="6"
              class="text-caption text-right elispsis copyTxt-mobile"
              v-else
              @click="copyToClipboard(supplyItem.ttn)"
            >
              {{ supplyItem.ttn || "--" }}</VCol
            >
          </VRow>
          <VRow no-gutters>
            <VCol cols="5" class="font-weight-bold text-caption elispsis">{{
              $t("table.phone")
            }}</VCol>
            <VCol cols="1">–</VCol>
            <VCol
              cols="6"
              class="text-caption text-right elispsis copyTxt-mobile"
              @click="copyToClipboard(supplyItem.delivery_address_details.phone)"
              >{{ supplyItem.delivery_address_details.phone || "--" }}</VCol
            >
          </VRow>
          <VRow no-gutters>
            <VCol cols="5" class="font-weight-bold text-caption elispsis">{{
              $t("table.name")
            }}</VCol>
            <VCol cols="1">–</VCol>
            <VCol cols="6" class="text-caption text-right elispsis ">{{
              supplyItem.delivery_address_details.name || "--"
            }}</VCol>
          </VRow>
        </VCol>
        <VCol cols="12" sm="2" class="text-center">
          <VDivider
            :vertical="$vuetify.breakpoint.sm"
            :class="{ 'my-3': $vuetify.breakpoint.xs }"
          />
        </VCol>
        <VCol cols="12" sm="5">
          <VRow no-gutters>
            <VCol cols="5" class="font-weight-bold text-caption elispsis">{{
              $t("table.surname")
            }}</VCol>
            <VCol cols="1">–</VCol>
            <VCol cols="6" class="text-caption text-right elispsis ">{{
              supplyItem.delivery_address_details.surname || "--"
            }}</VCol>
          </VRow>
          <VRow no-gutters>
            <VCol cols="5" class="font-weight-bold text-caption elispsis">{{
              $t("table.sum")
            }}</VCol>
            <VCol cols="1">–</VCol>
            <VCol cols="6" class="text-caption text-right elispsis ">{{
              supplyItem.items_total_price || "0"
            }}</VCol>
          </VRow>
          <VRow no-gutters>
            <VCol cols="5" class="font-weight-bold text-caption elispsis">{{
              $t("table.date")
            }}</VCol>
            <VCol cols="1">–</VCol>
            <VCol cols="6" class="text-caption text-right elispsis ">{{
              supplyItem.time_created | createdAt(supplyItem.time_created, currentLanguage)
            }}</VCol>
          </VRow>
          <VRow no-gutters align="center">
            <VCol cols="5" class="font-weight-bold text-caption elispsis">{{
              $t("table.status")
            }}</VCol>
            <VCol cols="1">–</VCol>
            <VCol cols="6" class="text-caption text-right elispsis ">
              <div
                @mouseenter="changeUserState = false"
                @mouseleave="changeUserState = true"
                v-click-outside="closeChangeUserState"
              >
                <VSelect
                  :items="colors"
                  item-text="name"
                  item-value="uuid"
                  placeholder="Оберіть "
                  v-model="supplyItem.uuid_user_state"
                  :hide-details="true"
                  @change="setUserState"
                  :disabled="changeUserState"
                  dense
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                >
                  <template slot="item" slot-scope="{ item }">
                    <div class="select-userState__item">
                      <span :style="`background-color: ${item.color};`"></span>
                      {{ item.name }}
                    </div>
                  </template>
                  <template slot="selection" slot-scope="{ item }">
                    <div class="select-userState__item">
                      {{ item.name }}
                    </div>
                  </template>
                </VSelect>
              </div>
            </VCol>
          </VRow>
        </VCol>
      </VRow>
    </VCardText>
  </VCard>
</template>

<script>
import supplyStatuses from "@/constants/states";
import SettingBoardDialog from "../SettingBoardDialog.vue";
// import AddSupplyOutDialog from './AddSupplyOutDialog.vue';
import OrderDialog from "@/components/orderDialog/OrderDialog.vue";
import user from "@/mixins/user";
import SizeUi from "@/mixins/SizeUi";
import notifications from "@/mixins/notifications";
import { mapGetters } from "vuex";
import * as getterTypes from "@/store/modules/crm/types/getters";
import EventBus from "@/events/EventBus";
import crmService from "@/services/request/crm/crmService";

export default {
  mixins: [user, SizeUi, notifications],
  components: {
    SettingBoardDialog,
    // AddSupplyOutDialog,
    OrderDialog
  },
  props: {
    supplyItem: {
      required: true
    },
    uuid_board: {
      required: true
    },
    colors: {
      require: false
    }
  },
  data: () => ({
    changeUserState: true,
    editInDialog: false,
    editOutDialog: false
  }),
  computed: {
    ...mapGetters(["currentLanguage"]),
    ...mapGetters("crm", {
      uuid_tab: getterTypes.GET_UUID_TAB
    }),
    bgItem() {
      // // orderDialog('this.uuid_tab', this.uuid_tab);
      if (this.colors.length && this.uuid_tab === "") {
        const colors = this.colors.filter(
          colorItem => colorItem.uuid === this.supplyItem.uuid_user_state
        );
        if (colors.length) {
          return colors[0].color;
        }
      }
      return "";
    }
  },
  methods: {
    closeChangeUserState() {
      this.changeUserState = true;
    },
    async copyToClipboard(e) {
      try {
        await navigator.clipboard.writeText(e);
        this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
      } catch (e) {
        console.log(e)
      }
    },
    async setUserState() {
      try {
        // orderDialog('setUserState');
        const formData = new FormData();
        formData.append("uuid_order", this.supplyItem.uuid);
        formData.append("uuid_state", this.supplyItem.uuid_user_state);
        formData.append("uuid_board", this.uuid_board);

        await crmService.setUserState(formData);
        this.changeUserState = false;
        EventBus.$emit("crm-items-reset");
      } catch (e) {
        console.log(e)
      }
    },
    userState(uuid) {
      const info = this.colors.filter(item => item.uuid === uuid);
      return info[0].name || "--";
    },
    disableBtn(id, client) {
      // eslint-disable-next-line no-mixed-operators
      if ((Number(id) > 400 && client) || Number(id) === 450) {
        return true;
      }
      return false;
    },
    getSupplyStatusText(code) {
      return supplyStatuses[code];
    },
    onSupplyItemClick() {
      EventBus.$emit("crm-item-edit", this.supplyItem);
    }
  }
};
</script>

<style lang="scss" scoped>
.delivery {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  div {
    max-width: 150px;
    width: auto;
    overflow: hidden;
    background: rgba(160, 192, 241, 0.5);
    border-radius: 10px;
    padding: 0px 10%;
    &.status {
      color: #4d7bc1;
      background: rgba(160, 192, 241, 0.5);
      font-weight: 600;
      font-size: 10px;
    }
  }
}
.blocked_wrapper {
  position: absolute;
  left: -1%;
  top: -3%;
}
</style>
