<template>
  <div>
    <VDialog
      v-model="visibility"
      :max-width="!$vuetify.breakpoint.smAndDown ? '1100px' : '700px'"
      content-class="cross__dialog"
      scrollable
      :retain-focus="false"
      :transition="$dialogTransition"
    >
      <v-card class="crmForms__listDialog" :width="$vuetify.breakpoint.smAndDown ? '330' : ''">
        <div class="cross__inner">
          <VBtn
            fab
            small
            elevation="0"
            @click="showConfirmDialog"
          >
            <v-icon>mdi-close</v-icon>
          </VBtn>
        </div>
        <v-card-title class="pt-7 pb-1">
          <v-row no-gutters>
            <v-col cols="12" class="pb-0">
              <v-row justify="space-between">
                <v-col>
                  <div class="d-flex justify-content-space-between">
                    <div>
                      <v-row align="center">
                        <v-col cols="auto">
                          <VBtn class=" text-transform-none" small color="primary" plain @click="backModal()">
                            <v-icon left>
                              mdi-arrow-left
                            </v-icon>
                            {{ $t('crm.forms') }}
                          </VBtn>
                        </v-col>
                        <v-col cols="auto" class="px-0">
                          <span color="primary" v-if="showBackFromProducts">/</span></v-col
                        >
                        <v-col cols="auto">
                          <VBtn
                            class=" text-transform-none"
                            small
                            color="primary"
                            v-if="showBackFromProducts"
                            plain
                            @click="onBackFormProduct"
                          >
                            <!-- <v-icon left>
                              mdi-arrow-left
                            </v-icon> -->
                            {{ $t('crm.productEditing') }}
                          </VBtn>
                        </v-col>
                        <v-col cols="auto" class="px-0">
                          <span color="primary" v-if="showBackFromListProducts">/</span></v-col
                        >
                        <v-col cols="auto">
                          <VBtn
                            class=" text-transform-none"
                            small
                            color="primary"
                            v-if="showBackFromListProducts"
                            plain
                            @click="onBackFromListProducts"
                          >
                            <!-- <v-icon left>
                              mdi-arrow-left
                            </v-icon> -->
                            {{ $t('crm.productEditing') }}
                          </VBtn>
                        </v-col>
                      </v-row>
                    </div>
                    <div :style="$vuetify.breakpoint.smAndDown ? 'margin-right:30px' : 'margin-right:0px'">

                      <toggle-button
                      :sync="true"
                      :width="43"
                      :height="20"
                      v-model="dataForm.is_maintenance"
                      :labels="{ checked: 'on', unchecked: 'off' }"
                      @change="onChangeStatus(dataForm)"
                      />
                      <VBtn
                      icon
                      class="sm"
                      color="primary"
                      depressed
                      dense
                      plain
                      @click="openForm(dataForm.uuid)"
                      >
                      <v-icon>
                        mdi-link
                      </v-icon>
                    </VBtn>
                  </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-tabs v-model="formSettingsTabs" color="success">
            <v-tab>
              <v-icon left>mdi-cog-transfer-outline</v-icon>
              <div v-if="!$vuetify.breakpoint.smAndDown">
                {{ $t("crm.settingtsTitleForm") }}
              </div>
            </v-tab>
            <v-tab>
              <v-icon left>mdi-package-variant-closed</v-icon>
              <div v-if="!$vuetify.breakpoint.smAndDown">
                {{ $t("crm.itemForm") }}
              </div>
            </v-tab>

            <v-tab>
              {{ $t("crm.header") }}
            </v-tab>
            <v-tab>
              {{ $t("crm.footer") }}
            </v-tab>
          </v-tabs>
          <v-divider />
          <div class="pb-1"></div>
          <v-tabs-items v-model="formSettingsTabs" class="pb-5">
            <!-- form settings -->
            <v-tab-item>
              <crm-form-setting-tab
                :errors="{
                  descriptionEditErrors: descriptionEditErrors,
                  nameEditErrors: nameEditErrors
                }"
                :nameEditErrors="nameEditErrors"
                :dataForm="dataFormCopy"
                @back="$emit('back')"
                :resetTabs="resetTabs"
              />
            </v-tab-item>
            <v-tab-item>
              <crm-form-items
                :onBackFromProductList="clickBackFromListProducts"
                :onBackFormProduct="clickBackFromProduct"
                @back="$emit('back')"
                :resetTabs="resetTabs"
                :dataForm="dataFormCopy"
              />
            </v-tab-item>
            <!-- form header -->
            <v-tab-item>
              <crm-header-tab @back="$emit('back')" :dataForm="dataFormCopy" />
            </v-tab-item>
            <!-- form footer -->
            <v-tab-item>
              <crm-footer-tab @back="$emit('back')" :dataForm="dataFormCopy" />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions v-if="formSettingsTabs !== 1">
          <v-row class="pb-3">
              <!-- <VBtn class="w-100 br-10 text-transform-none" dark @click="backModal()">
                {{ $t("btn.back") }}
              </VBtn> -->

            <v-col cols="6" class="mx-auto">
              <VBtn
                class="success-bg text-white text-transform-none br-10 w-100"
                @click="updateFormsItem"
                :disabled="!isEqualFormData"
              >
                {{ $t("btn.save") }}
              </VBtn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </VDialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import language from "../../../../mixins/language";
import notifications from "../../../../mixins/notifications";
import CrmFormSettingTab from "./tabs/crmFormSettingTab.vue";
import crmFooterTab from "./tabs/crmFooterTab.vue";
import crmHeaderTab from "./tabs/crmHeaderTab.vue";
import crmFormItems from "./tabs/crmFormItems.vue";
import crmForms from "@/services/request/crm/crmForms";
import EventBus from "@/events/EventBus";
import { required } from "vuelidate/lib/validators";
import _ from "lodash";

export default {
  mixins: [validationMixin, language, notifications],
  name: "crmFormSetting",
  components: {
    CrmFormSettingTab,
    crmFooterTab,
    crmHeaderTab,
    crmFormItems
  },
  props: {
    visible: {
      required: true,
      type: Boolean
    },
    dataForm: {
      required: true,
      type: Object
    }
  },
  validations: {
    dataFormCopy: {
      name: { required }
    },
    productForm: {
      name: { required },
      description: { required }
    }
  },
  data: () => ({
    formSettingsTabs: "",
    resetTabs: false,
    dataFormCopy: {},
    isEqualFormData: false,
    showBackFromProducts: false,
    clickBackFromProduct: false,
    showBackFromListProducts: false,
    clickBackFromListProducts: false
  }),
  methods: {
    // eslint-disable-next-line
    async onChangeStatus({ uuid, is_maintenance }) {
      try {
        const formData = new FormData();
        formData.append("uuid", uuid);
        // eslint-disable-next-line
        formData.append("is_maintenance", is_maintenance ? "0" : "1");
        const res = await crmForms.updateItemForm(formData);
        if (res) {
          this.setSuccessNotification(this.$t('profile.successfully_updated'));
        }
      } catch (e) {
        console.log(e)
      }
    },
    onBackFormProduct() {
      this.showBackFromProducts = false;
      this.clickBackFromProduct = !this.clickBackFromProduct;
      this.showBackFromListProducts = false;
    },
    onBackFromListProducts() {
      this.showBackFromListProducts = false;
      this.clickBackFromListProducts = !this.clickBackFromListProducts;
    },
    backModal() {
      this.$emit("back");
    },
    async updateFormsItem() {
      this.$v.dataFormCopy.$touch();
      if (!this.$v.dataFormCopy.$invalid) {
        try {
          const formData = new FormData();
          formData.append("uuid", this.dataFormCopy.uuid);
          const changedData = this.$getChangedData(this.dataFormCopy, this.dataForm);
          changedData.forEach(data => {
            formData.append(data.field, data.value);
          });
          const res = await crmForms.updateItemForm(formData);
          if (res) {
            this.setSuccessNotification(this.$t('profile.successfully_updated'));
            this.isEqualFormData = false;
            // EventBus.$emit("dataForm-modified", this.dataFormCopy);
          }
        } catch (e) {
          console.log(e)
        }
      }
    },
    onClose() {
      this.visibility = false;
    },
    showConfirmDialog() {
      if (this.changed) {
        this.visibleConfirm = true;
      } else {
        this.onClose();
      }
    },
    openForm(uuid) {
      const route = this.$router.resolve({
        name: "market",
        params: {
          id: uuid
        }
      });
      window.open(route.href, "_blank");
    }
  },
  beforeDestroy(){
    EventBus.$off("on-tab-product");
    EventBus.$off("on-tab-list-product");
  },
  mounted() {
    EventBus.$on("on-tab-product", data => {
      this.showBackFromProducts = data;
    });
    EventBus.$on("on-tab-list-product", data => {
      this.showBackFromListProducts = data;
    });
    this.dataFormCopy = { ...this.dataForm };
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.resetTabs = true;
        this.$emit("close");
      }
    },
    nameEditErrors() {
      const errors = [];
      if (!this.$v.dataFormCopy.name.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.dataFormCopy.name.required && errors.push(this.$t("form.errors.TitleRequierd"));
      return errors;
    },
    descriptionEditErrors() {
      const errors = [];
      // if (!this.$v.dataFormCopy.description.$dirty) {
      //   return errors;
      // }
      // // eslint-disable-next-line no-unused-expressions
      // !this.$v.dataFormCopy.description.required &&
      //   errors.push(this.$t("form.errors.DescRequired"));
      return errors;
    },
    nameProductFormErrors() {
      const errors = [];
      if (!this.$v.productForm.name.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.productForm.name.required && errors.push(this.$t("form.errors.TitleRequierd"));
      return errors;
    },
    descriptionProductFormErrors() {
      const errors = [];
      if (!this.$v.productForm.description.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.productForm.description.required && errors.push(this.$t("form.errors.DescRequired"));
      return errors;
    }
  },
  watch: {
    formSettingsTabs(tab){
        this.showBackFromProducts = false
        this.showBackFromListProducts = false
        this.clickBackFromProduct = !this.clickBackFromProduct
    },
    dataFormCopy: {
      deep: true,
      handler() {
        this.isEqualFormData = !_.isEqual(this.dataFormCopy, this.dataForm);
      }
    },
    dataForm: {
      deep: true,
      handler() {
        this.dataFormCopy = { ...this.dataForm };
      }
    }
  }
};
</script>
