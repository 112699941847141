<template>
  <CrmComponent></CrmComponent>
</template>

<script>
import CrmComponent from '../components/crm/CrmComponent.vue';

export default {
  name: 'CRM',
  components: {
    CrmComponent,
  },
};
</script>

<style lang="scss">
.new-bg {
  // background-color: #fff;
}
</style>
