<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <VBtn
          class="text-white success-bg btn-settingBoard text-transform-none br-10"
          @click="statsVisible = !statsVisible"
          v-bind="attrs"
          v-on="on"
          :disabled="!uuid_board"
        >
          <VIcon>mdi-chart-box-plus-outline</VIcon>
        </VBtn>
      </template>
      <span>{{ $t("crm.statsTitle") }}</span>
    </v-tooltip>
    <VDialog
      v-model="statsVisible"
      v-if="statsVisible"
      max-width="700px"
      content-class="cross__dialog dialog-order"
      fullscreen
      scrollable
      :transition="$dialogTransition"
    >
      <VCard>
        <VCardTitle>
          <v-row>
            <v-col class="d-flex align-center pb-1" cols="2">
              <span>
                {{ $t("crm.statsTitle") }}
              </span>
            </v-col>
            <v-col class="pb-1" cols="9">
              <v-tabs
                v-show="isAllowStatisticReview"
                color="grey"
                v-model="statTab"
              >
                <v-tab> {{ $t("crm.stats_basic") }} </v-tab>
                <v-tab> {{ $t("crm.stats_profit") }} </v-tab>
                <v-tab> {{ $t("crm.stats_crm_users") }}</v-tab>
                <v-tab> {{ $t("crm.stats_tags") }} </v-tab>
                <v-tab disabled> {{ $t("menu.depots_products") }} </v-tab>
              </v-tabs>
            </v-col>
            <v-col class="d-flex align-center pb-1" cols="1">
              <VBtn class="br-10 text-transform-none" color="primary" plain @click="onClose">
                <VIcon left>
                  mdi-arrow-left
                </VIcon>
                {{ $t("btn.back") }}
              </VBtn>
            </v-col>
          </v-row>
        </VCardTitle>
        <VDivider class="mx-3"></VDivider>
        <VCardText class="py-4 pt-0">
          <v-row>
            <v-col class="d-flex align-center" cols="2">
              <v-menu
                ref="menuFirstDate"
                v-model="menuFirstDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="text-caption"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    solo
                    :placeholder="$t('crm.firstDate')"
                    v-model="dateRangeText"
                    readonly
                    append-icon="mdi-calendar"
                    dense
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filterData.dates"
                  range
                  no-title
                  scrollable
                  :locale="currentLanguage.key"
                  min="2021"
                  :max="new Date().toISOString().substr(0, 10)"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-slide-x-reverse-transition>
              <v-col v-if="isChanged || statisticData.length" class="d-flex align-center" cols="auto">
                <VBtn color="grey" @click="onResetFilter" icon>
                  <VIcon>mdi-broom</VIcon>
                </VBtn>
              </v-col>
            </v-slide-x-reverse-transition>
              <v-col class="d-flex align-center my-2" cols="auto">
                <VBtn color="success" @click="onSaveBtn">{{
                    $t("btn.apply_filters")
                  }}</VBtn>
              </v-col>
            <v-row>
              <v-col v-if="statisticData.length" class="d-flex align-center justify-end" cols="12">
                <vue-json-to-csv
                  :json-data="statisticData"
                  :csv-title="statisticTitle"
                >
                  <VBtn color="grey" icon>
                    <VIcon>mdi-file-export</VIcon>
                  </VBtn>
                </vue-json-to-csv>
                <VBtn color="grey" @click="onOpenChart()" icon>
                  <VIcon>mdi-chart-bar</VIcon>
                </VBtn>
              </v-col>
            </v-row>
          </v-row>
          <VDivider v-if="isAllowStatisticReview" />
          <div v-if="statTab === 0 || statTab === 1 || statTab === 2 || statTab === 3 ">
            <v-chip-group
              multiple
              :show-arrows="true"
              active-class="category-chips__active"
              v-model="choosenState"
            >
              <v-chip
                v-for="state in states"
                :key="state.uuid"
                small
                :disabled="
                  chipOptions.disabled &&
                  state.uuid != '00000000-0000-0000-0000-000000000000'
                "
                outlined
                class="category-chips"
                :value="state.uuid"
              >
                <template>
                  <div class="user-state">
                    {{ state?.name }}
                    <span :style="`background-color: ${state.color};`"></span>
                  </div>
                </template>
              </v-chip>
            </v-chip-group>
          </div>
          <v-row no-gutters>
            <v-tabs-items v-model="statTab">
              <v-tab-item key="basic">
                <v-col cols="12">
                  <StatsFilter :chartData="chartData" :basic="basic" :filterData="filterData" @getFiltereUTM="getFiltereUTM"/>
                </v-col>
              </v-tab-item>
              <v-tab-item key="profit">
                <v-col cols="12" v-if="isAllowStatisticReview">
                  <StatsFilterProfit ref="profit" :chartData="chartData"/>
                </v-col>
              </v-tab-item>
              <v-tab-item key="assigned">
                <v-col class="pa-0" cols="12" v-if="isAllowStatisticReview">
                  <StatsFilterAssigned :chartData="chartData" :filteredAssigned="filteredAssigned"/>
                </v-col>
              </v-tab-item>
              <v-tab-item key="tags">
                <v-col cols="12" v-if="isAllowStatisticReview">
                  <StatsFilterTags ref="tags" :tagsData="tagsData"/>
                </v-col>
              </v-tab-item>
            </v-tabs-items>
          </v-row>
        </VCardText>
        <VCardActions> </VCardActions>
      </VCard>
    </VDialog>
    <VDialog
      v-model="showChart" max-width="700px" scrollable content-class="cross__dialog" @click:outside="onCloseChart"
    >
      <VCard>
        <VCardTitle><v-row>
          <div class="cross__inner">
            <VBtn fab small elevation="0" @click="onCloseChart()">
              <VIcon>mdi-close</VIcon>
            </VBtn>
          </div>
        </v-row>
        </VCardTitle>
        <VCardText>
          <div v-if="statTab === 2" style="max-width: 400px">
            <v-chip-group
              multiple
              column
              :show-arrows="true"
              active-class="category-chips__active"
              v-model="choosenAssignedUserModel"
            >
              <v-chip
                v-for="data in chartData"
                :key="data.uuid_user_assigned_to"
                outlined
                class="category-chips"
                :value="data.uuid_user_assigned_to"
              >
                <template v-if="data.name && data.surname">
                  {{ `${data.name} ${data.surname}` }}
                </template>
                <template v-else-if="data.name && !data.surname">
                  {{ `${data.name}` }}
                </template>
                <template v-else-if="!data.name && data.surname">
                  {{ `${data.surname}` }}
                </template>
                <template v-else-if="!data.name && !data.surname">
                  {{ $t('crm.not_assigned_crm_users') }}
                </template>
              </v-chip>
            </v-chip-group>
          </div>
          <StatsChart
            :resetChart="resetChart"
            @resetChart="chartData = []"
            :chartData="filteredAssigned"
            :type="typeChart"
            :isLoading="loadingChart"
          />
        </VCardText>
      </VCard>
    </VDialog>
  </div>
</template>

<script>
import StatsFilter from "./StatsFilter.vue";
import StatsFilterAssigned from "./StatsFilterAssigned.vue";
import StatsFilterProfit from "./StatsFilterProfit.vue";
import StatsFilterTags from "./StatsFilterTags.vue";
import StatsChart from "./StatsChart.vue";
import notifications from "@/mixins/notifications";
import crmService from "../../../services/request/crm/crmService";
import {mapGetters} from "vuex";
import _ from "lodash";
// eslint-disable-next-line import/no-extraneous-dependencies
import VueJsonToCsv from "vue-json-to-csv/src/JsonToCsv.vue";

const date = new Date();
const y = date.getFullYear();
const m = date.getMonth() + 1;
const d = date.getDate();

export default {
  data: () => ({
    chartData: [],
    statsVisible: false,
    resetFilters: false,
    loadingChart: false,
    statTab: 0,
    typeChart: "basic",
    resetChart: false,
    choosenState: [],
    choosenAssignedUser:[],
    tagsData: Object,
    utm_sources: [],
    utm_mediums: [],
    utm_terms: [],
    utm_contents: [],
    utm_campaigns: [],
    showChart: false,
    choosenAssignedUserModel: [],
    copyFilterData: {
      dates: [new Date(y, m - 1, d).toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)],
    },
    copyFilterDataBasic: {
      dates: [new Date(y, m - 1, d).toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)],
      utm_filters: [
        {
          show: false,
          value: "",
          text: "utm_source",
          values: [],
          loading: false,
        },
        {
          show: false,
          value: "",
          text: "utm_medium",
          values: [],
          loading: false,
        },
        {
          show: false,
          value: "",
          text: "utm_term",
          values: [],
          loading: false,
        },
        {
          show: false,
          value: "",
          text: "utm_content",
          values: [],
          loading: false,
        },
        {
          show: false,
          value: "",
          text: "utm_campaign",
          values: [],
          loading: false,
        },
      ],
    },
    filterData: {
      dates: [new Date(y, m - 1, d).toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)],
      utm_filters: [
        {
          show: false,
          value: "",
          text: "utm_source",
          values: [],
          loading: false,
        },
        {
          show: false,
          value: "",
          text: "utm_medium",
          values: [],
          loading: false,
        },
        {
          show: false,
          value: "",
          text: "utm_term",
          values: [],
          loading: false,
        },
        {
          show: false,
          value: "",
          text: "utm_content",
          values: [],
          loading: false,
        },
        {
          show: false,
          value: "",
          text: "utm_campaign",
          values: [],
          loading: false,
        },
      ],
    },
    predefinedParam: null,
    menuFirstDate: false,
    menuLastDate: false,
    isChanged: false,
    chosenState: [],
    dateRangeText: [],
    chipOptions: {
      disabled: false,
    },
    basic: {
      amount: '--',
      sent: '--',
      returned: '--',
      pending: '--'
    },
    predefinedParams: [
      {
        value: 30,
        type: "month",
        text: "crm.month",
      },
      {
        value: 6,
        type: "week",
        text: "crm.week",
      },
      {
        value: 0,
        type: "day",
        text: "crm.day",
      },
    ],
  }),
  props: {
    isAllowStatisticReview: {
      require: true,
    },
    uuid_board: {
      require: true,
    },
    boardUuid: {
      require: true,
    },
    states: {
      require: true,
    },
  },
  computed:{
    ...mapGetters(["currentLanguage"]),
    filteredAssigned(){
      if(this.choosenAssignedUser.length){
        return [...this.chartData.filter(chart => this.choosenAssignedUser.includes(chart.uuid_user_assigned_to))]
      }
      if(this.typeChart === 'assigned'){
        return []
      }
      return this.chartData
    },
    statisticData(){
      if(this.statTab === 0 || this.statTab === 1) {
        return this.chartData;
      } else if(this.statTab === 2) {
        return this.filteredAssigned;
      } else {
        return [this.tagsData];
      }
    },
    statisticTitle(){
      if(this.statTab === 0 ) {
        return 'Basic';
      } else if(this.statTab === 1) {
        return 'Profit';
      } else if(this.statTab === 2) {
        return 'CRM Users';
      } else if(this.statTab === 3) {
        return 'Tags'
      } else {
        return 'Products'
      }
    },
  },
  mounted() {
    this.dateRangeText = this.filterData.dates.join(' ~ ');
  },
  watch: {
    // predefinedParam: {
    //   handler() {
    //     const date = new Date();
    //     this.filterData.lastDate = date.toISOString().substr(0, 10);
    //
    //     if (this.predefinedParam.type != "month") {
    //       date.setDate(date.getDate() - this.predefinedParam.value);
    //     } else {
    //       date.setMonth(date.getMonth() - 1);
    //     }
    //     this.filterData.firstDate = date.toISOString().substr(0, 10);
    //   },
    // },
    choosenAssignedUser(e) {
      this.choosenAssignedUserModel = e;
      this.$forceUpdate();
    },
    choosenAssignedUserModel(e) {
      this.changeChoosenAssignedUser(e);
    },
    filterData: {
      deep: true,
      handler() {
        if(this.statTab === 0) {
          this.isChanged = !_.isEqual(
            this.copyFilterDataBasic,
            this.filterData
          );
        } else {
          this.isChanged = !_.isEqual(
            this.copyFilterData,
            this.filterData
          );
        }
      },
    },
    resetFilters: {
      handler() {
        this.onResetFilter();
      }
    },
    choosenState: {
      handler() {
        if (
          this.choosenState.find(
            (e) => e === "00000000-0000-0000-0000-000000000000"
          )
        ) {
          this.chipOptions = {
            disabled: true,
          };
        } else {
          this.chipOptions = {
            disabled: false,
          };
        }
      }
    },
    statTab(e) {
      this.chartData = [];
      this.tagsData = {};
      this.basic = {
        amount: '--',
        sent: '--',
        returned: '--',
        pending: '--'
      };
      this.choosenAssignedUser = [];
      this.resetChart = !this.resetChart;
      this.resetFilters = !this.resetFilters;
      this.choosenState = [];
      this.typeChart = '';
      this.$forceUpdate();
    },
  },
  methods: {
    onOpenChart() {
      this.onSaveBtn();
      this.showChart = true;
    },
    onCloseChart() {
      this.showChart = false;
    },
    async getFiltereUTM(utmValue) {
      try {
        this.filterData.utm_filters.forEach(async (utm) => {
          if (utm.text === utmValue) {
            if (utm.values.length) return;
            utm.loading = true;
            utm.values = await crmService.crmUTMlist({
              uuid_board: this.uuid_board,
              utm_parameter: utm.text,
            });
            utm.loading = false;
          }
        });
      } catch (e) {
        console.log(e)
      }
    },
    onSaveBtn() {
      let params = {};
      if(this.filterData.dates.length === 2) {
        const firstDate = new Date(this.filterData.dates[0]);
        const secondDate = new Date(this.filterData.dates[1]);
        if (firstDate > secondDate) {
          this.dateRangeText = `${this.filterData.dates[1]} ~ ${this.filterData.dates[0]}`;
          params = {
            date_start: this.filterData.dates[1],
            date_end: this.filterData.dates[0],
          };
        } else {
          this.dateRangeText = this.filterData.dates.join(' ~ ');
          params = {
            date_start: this.filterData.dates[0],
            date_end: this.filterData.dates[1],
          };
        }
      } else {
        this.dateRangeText = `${this.filterData.dates[0]} ~ ${this.filterData.dates[0]}`;
        params = {
          date_start: this.filterData.dates[0],
          date_end: this.filterData.dates[0],
        };
      }

      if(this.statTab === 0) {
        this.filterData.utm_filters.forEach((utm) => {
          if (utm.value && utm.show) {
            params[utm.text] = utm.value;
          }
        });
        this.onSave(params);
      } else if (this.statTab === 1){
        this.onSaveProfit(params);
      } else if (this.statTab === 2){
        this.onSaveAssigned(params);
      } else if(this.statTab === 3){
        this.onSaveTags(params);
      }
      //   this.isChanged = !this.isChanged
    },
    onResetFilter() {
      this.predefinedParam = this.predefinedParams[1]
      if(this.statTab === 0) {
        this.basic = {
          amount: '--',
          sent: '--',
          returned: '--',
          pending: '--'
        };
        this.chartData = [];
        this.choosenState = [];
        this.dateRangeText = [
          new Date(y, m - 1, d).toISOString().substr(0, 10),
          new Date().toISOString().substr(0, 10)
        ].join(' ~ ');
        this.filterData = {
          dates: [new Date(y, m - 1, d).toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)],
          utm_filters: [
            {
              show: false,
              value: "",
              text: "utm_source",
              values: [],
              loading: false,
            },
            {
              show: false,
              value: "",
              text: "utm_medium",
              values: [],
              loading: false,
            },
            {
              show: false,
              value: "",
              text: "utm_term",
              values: [],
              loading: false,
            },
            {
              show: false,
              value: "",
              text: "utm_content",
              values: [],
              loading: false,
            },
            {
              show: false,
              value: "",
              text: "utm_campaign",
              values: [],
              loading: false,
            },
          ],
        }
      } else {
        this.chartData = [];
        this.tagsData = [];
        this.choosenState = [];
        this.filterData = {
          dates: [new Date(y, m - 1, d).toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)],
        };
        this.dateRangeText = [
          new Date(y, m - 1, d).toISOString().substr(0, 10),
          new Date().toISOString().substr(0, 10)
        ].join(' ~ ');
      }
    },
    changeChoosenAssignedUser(e){
      this.choosenAssignedUser = e
    },
    getTotalBasic(items) {
        items.forEach((item) => {
          this.basic.amount += parseFloat(item.amount);
          this.basic.sent += parseFloat(item.sent_amount);
          this.basic.returned += parseFloat(item.return_amount);
          this.basic.pending = this.basic.amount - this.basic.sent;
        });
    },
    totalBasicReset() {
      this.basic.amount = 0;
      this.basic.sent = 0;
      this.basic.returned = 0;
      this.basic.pending = 0;
    },
    async onSave(data) {
      try {
        this.loadingChart = true;
        const params = {
          ...data,
          uuid_board: this.boardUuid.uuid,
        };
        this.totalBasicReset();
        if(!this.chipOptions.disabled) {
          params.uuid_user_state = this.choosenState;
        }
        const res = await crmService.crmStats(params);
        this.resetChart = !this.resetChart;
        this.typeChart = "basic";
        this.chartData = res;
        this.getTotalBasic(this.chartData);
      } catch (e) {
        console.log(e)
        this.loadingChart = false;
      } finally {
        this.loadingChart = false;
      }
    },
    async onSaveAssigned(data) {
      try {
        this.loadingChart = true;
        const params = {
          ...data,
          uuid_board: this.boardUuid.uuid,
        };
        if(!this.chipOptions.disabled) {
          params.uuid_user_state = this.choosenState;
        }
        const res = await crmService.crmStatsAssigned(params);
        this.resetChart = !this.resetChart;
        this.typeChart = "assigned";
        this.chartData = res;
        this.choosenAssignedUser = [...this.chartData.map(e => e.uuid_user_assigned_to)]
      } catch (e) {
        console.log(e)
        this.loadingChart = false;
      } finally {
        this.loadingChart = false;
      }
    },
    async onSaveProfit(data) {
      try {
        this.loadingChart = true;

        const params = {
          ...data,
          uuid_board: this.boardUuid.uuid,
        };
        if(!this.chipOptions.disabled) {
          params.uuid_user_state = this.choosenState;
        }
        const res = await crmService.crmStatsProfite(params);
        this.resetChart = !this.resetChart;
        this.typeChart = "profit";
        this.chartData = res;
        this.loadingChart = false;
      } catch (e) {
        console.log(e)
        this.loadingChart = false;
      } finally {
        this.loadingChart = false;
      }
    },
    async onSaveTags(data){
      try {
        this.loadingChart = true;
        const params = {
          ...data,
          uuid_board: this.boardUuid.uuid,
        };
        if(!this.chipOptions.disabled) {
          params.uuid_user_state = this.choosenState;
        }
        const res = await crmService.getTagStats(params);
        // this.resetChart = !this.resetChart;
        this.typeChart = "tags";
        this.chartData = res;
        this.tagsData = this.chartData.object.tags;
        this.loadingChart = false;
      } catch (e) {
        console.log(e)
        this.loadingChart = false;
      } finally {
        this.loadingChart = false;
      }
    },
    onClose() {
      this.statsVisible = false;
      this.chartData = [];
      this.resetFilters = !this.resetFilters;
      this.resetChart = !this.resetChart;
    },
  },
  components: {
    VueJsonToCsv,
    StatsFilter,
    StatsChart,
    StatsFilterAssigned,
    StatsFilterProfit,
    StatsFilterTags,
  },
  mixins: [notifications],
};
</script>


<style lang="scss" scoped>
.category-chips__active{
  background:red!important;
}
</style>
