<template>
  <div>
    <v-row class="mt-1">
      <v-col cols="12">
        <vue2-tinymce-editor
          v-model="dataForm.footer"
          :options="options"
        ></vue2-tinymce-editor>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from "lodash";
import { Vue2TinymceEditor } from "vue2-tinymce-editor";
import crmForms from "@/services/request/crm/crmForms";
import notifications from "@/mixins/notifications";
import EventBus from "@/events/EventBus";

export default {
  data: () => ({
    isEqualFormData: false,
    options: {
      height: 500,
      skin: "oxide-dark",
      content_css: "dark",
      plugins: "code image fullscreen importcss table media preview",
      toolbar1:
        "fontselect | fontsizeselect | formatselect | bold italic underline strikethrough forecolor backcolor",
      toolbar2:
        "alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | link table removeformat code"
    }
  }),
  mixins: [notifications],
  components: {
    Vue2TinymceEditor
  },
  props: {
    dataForm: {
      require: true
    }
  },
  mounted() {},

  methods: {
    backModal() {
      this.$emit("back");
    },
    async updateFormsItem() {
      try {
        const formData = new FormData();
        formData.append("uuid", this.dataForm.uuid);
        formData.append("footer", this.dataForm.footer);
        const res = await crmForms.updateItemForm(formData);
        if (res) {
          this.setSuccessNotification(this.$t("profile.successfully_updated"));
          this.isEqualFormData = false;
          // EventBus.$emit("dataForm-modified", this.dataForm);
        }
        this.isEqualFormData = false;
      } catch (e) {
        console.log(e)
      }
    }
  }
};
</script>

<style></style>
